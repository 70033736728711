import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Form, Button, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import "../../../assets/styles/css/index.css";
import eyeIcon from "../../../assets/images/icons/eye-regular2.svg";
import errorIcon from "../../../assets/images/icons/circle-exclamation-solid 2.png";
import logoInkupSidebar from "../../../assets/images/logoInkupSidebar.svg";
import { AuthOperations } from "../../../redux/auth/authOperators";
import ReactPhoneInput from "react-phone-input-2";
import iconSolidStore from "../../../assets/images/icon-solid-store.svg";
import instagramIcon from "../../../assets/images/icon-Instagram.svg";
import iconGlobe from "../../../assets/images/icon-globe.svg";
import iconSolidUserAlt from "../../../assets/images/icon-solid-user-alt.svg";
import iconSolidEnvelope from "../../../assets/images/icon-solid-envelope.svg";
import iconCollapsed from "../../../assets/images/icon-collapse.svg";

export const SignupComponent = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [checkTerms, setCheckTerms] = useState(false);
  const [studioName, setStudioName] = useState("");
  const [name_user, setNameUser] = useState("");
  const [domain, setDomain] = useState(studioName);
  const [phone, setPhone] = useState("");
  const [prefix, setPrefix] = useState("34");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isStudio, setIsStudio] = useState(1);
  const [inquiries, setInquiries] = useState("");
  const [newState, flipToggle] = useState(false);
  const [phoneValidation, setPhoneValidation] = useState(null);
  const [t] = useTranslation("global");
  const errorRegister = useSelector((state) => state.auth.error.errorType);

  const handleToggle = () => {
    flipToggle(!newState);
  };

  const handleChangePrefix = (e) => {
    let prefix = e.slice(0, 2);
    setPrefix(prefix);
    setPhone(e);
    if (e.length === 11 && prefix === "34") {
      setPhoneValidation(true);
    } else if (e.length >= 10 && prefix !== "34") {
      setPhoneValidation(true);
    } else {
      setPhoneValidation(false);
    }
  };

  const onFormSubmit = async () => {
    if (
      (phone.length === 11 && prefix === "34") ||
      (phone.length >= 10 && prefix !== "34")
    ) {
      setPhoneValidation(true);
    } else {
      setPhoneValidation(false);
    }

    if (phoneValidation) {
      try {
        await dispatch(
          AuthOperations.setSignUp({
            studioName,
            domain,
            name_user,
            phone,
            email,
            password,
            isStudio,
            inquiries,
          })
        );
        navigate("/pageConfiguration");
      } catch (error) {}
    }
  };

  function handleChange(event) {
    setStudioName(event.target.value);
    setDomain(event.target.value.replace(/ /g, "-"));
  }
  return (
    <Row className="totalHeight">
      <Col lg={6} className="registerTattoo d-none d-md-block "></Col>
      <Col md={12} lg={6} className="d-block mx-0 p-0">
        <div className="topContentSignUp">
          <Link to="/login" className="linkBtnStartSesion">
            <img src={iconCollapsed} alt="logo" />
            <p className="mb-0 ps-2">{t("signup.login")}</p>
          </Link>
          <div className="">
            <img src={logoInkupSidebar} className="logo-img" alt="logo" />
          </div>
        </div>
        <h2 className="mx-auto my-3 mb-2 text-center headingH2">
          {t("signup.title")}
        </h2>
        <div className="ps-3 pe-3">
          <Form onSubmit={onFormSubmit} className="div-form-singup">
            <div className="font-size-form mb-2">
              <Form.Group controlId="formBasicSelect">
                <Form.Label className="text-grey-color font-size-form">
                  {t("signup.select")}
                </Form.Label>
                <Form.Control
                  className="singupLabel"
                  as="select"
                  value={isStudio}
                  onChange={(e) => {
                    setIsStudio(e.target.value);
                  }}
                >
                  <option value="1">{t("signup.study")}</option>
                  <option value="0">{t("signup.artist")}</option>
                  <option value="4">{t("signup.piercer")}</option>
                </Form.Control>
              </Form.Group>
            </div>
            <div className="font-size-form">
              <Form.Group className="mb-3" controlId="formInstagram">
                  <Form.Label className="text-grey-color font-size-form">
                    {t("signup.professionalInstagram")}
                  </Form.Label>
                <div className="div-img-text-singup">
                  <img src={instagramIcon} alt="Icon nombre"></img>
                  <Form.Control
                    className="professionalInstagram style-input-generic-singup"
                    autoComplete="nope"
                    type="text"
                    required
                    placeholder={t("signup.professionalInstagramPlaceHolder")}
                    onChange={handleChange}
                  />
                </div>
                {errorRegister === 5 && (
                  <p className="text-error">
                    {t("signup.errorEmptyStudioName")}
                  </p>
                )}
                {errorRegister === 6 && (
                  <p className="text-error">
                    {t("signup.errorEmptyStudioName2")}
                  </p>
                )}
                {errorRegister === 1 && (
                  <p className="text-error">
                    {t("signup.errorDuplicatedDomain")}
                  </p>
                )}
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicName">
                <Form.Label className="text-grey-color font-size-form">
                  {t("profile.name")}
                </Form.Label>
                <div className="div-img-text-singup">
                  <img src={iconSolidUserAlt} alt="Icon nombre"></img>
                  <Form.Control
                    className="businessArtistName style-input-generic-singup"
                    autoComplete="nope"
                    type="text"
                    placeholder={t("profile.nameBox")}
                    onChange={(e) => setNameUser(e.target.value)}
                  />
                </div>
              </Form.Group>
              <Form.Group
                className="div-phone-number mb-3"
                controlId="formBasicTel"
              >
                <Form.Label className="text-grey-color font-size-form">
                  {t("signup.phone")}
                </Form.Label>
                <div className="password-input-container">
                  <ReactPhoneInput
                    id="idInputPhoneNumberForm"
                    inputClass="input-prefix-signup"
                    className="businessPhonePrefix unsetPrefix "
                    autoComplete="nope"
                    type="phone"
                    // country={
                    //   window.navigator.language.slice(0, 2) === "en"
                    //     ? window.navigator.language.slice(3, 5).toLowerCase() ||
                    //     "us"
                    //     : window.navigator.language.slice(0, 2)
                    // }
                    country={
                      window.navigator.language.slice(0, 2) === "en"
                        ? window.navigator.language.slice(3, 5).toLowerCase() ||
                          "us"
                        : window.navigator.language.slice(0, 2) === "es"
                        ? window.navigator.language.slice(3, 5).toLowerCase() ||
                          "es"
                        : window.navigator.language.slice(0, 2)
                    }
                    inputProps={{
                      name: "phone",
                      required: true,
                    }}
                    onChange={(e) => handleChangePrefix(e, "phone")}
                    require="true"
                  />
                  <div className="icon-container">
                    {phoneValidation === false && (
                      <img
                        className="error-icon"
                        src={errorIcon}
                        alt="errorIcon"
                      />
                    )}
                  </div>
                </div>
                {phoneValidation === false && (
                  <div className="">
                    <p className="text-error-phone">{t("signup.validPhone")}</p>
                  </div>
                )}
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label className="text-grey-color font-size-form">
                  {t("signup.email")}
                </Form.Label>
                <div className="div-img-text-singup">
                  <img src={iconSolidEnvelope} alt="Icono del nombre"></img>
                  <Form.Control
                    className="businessEmail style-input-generic-singup"
                    autoComplete="nope"
                    type="email"
                    placeholder={t("profile.emailBox")}
                    onChange={(e) => setEmail(e.target.value)}
                    onPaste={(e) => {
                      e.preventDefault();
                      return false;
                    }}
                    onCopy={(e) => {
                      e.preventDefault();
                      return false;
                    }}
                    required
                  />
                  {errorRegister === 2 && (
                    <p className="text-error">
                      {t("signup.errorDuplicatedEmail")}
                    </p>
                  )}
                </div>
              </Form.Group>
              <Form.Group className="inputEye" controlId="formBasicPassword">
                <Form.Label className="text-grey-color font-size-form">
                  {t("signup.password")}
                </Form.Label>
                <div className="password-input-container">
                  <Form.Control
                    autoComplete="nope"
                    onChange={(e) => setPassword(e.target.value)}
                    type={newState ? "text" : "password"}
                    name="password"
                    placeholder={t("signup.boxPassword")}
                    onPaste={(e) => {
                      e.preventDefault();
                      return false;
                    }}
                    onCopy={(e) => {
                      e.preventDefault();
                      return false;
                    }}
                    className={`businessPassword style-input-password-singup && form-control`}
                  />
                  <div className="icon-container">
                    {errorRegister === 3 && (
                      <img
                        className="error-icon"
                        src={errorIcon}
                        alt="errorIcon"
                      />
                    )}
                    <img
                      onClick={handleToggle}
                      className="eye-register"
                      src={eyeIcon}
                      alt=""
                    />
                  </div>
                </div>

                {errorRegister === 3 ? (
                  <p className="invalid-feedback characterPassword2 d-block">
                    {t("signup.character")}
                  </p>
                ) : (
                  <p className="characterPassword">{t("signup.character")}</p>
                )}
              </Form.Group>
              <Form.Group className="mb-3" controlId="formInquiries">
                <Form.Label className="text-grey-color font-size-form">
                  {t("signup.inquiriesPerDay")}
                </Form.Label>
                <div className="div-img-text-singup">
                  <Form.Control
                    className="style-input-inquiries"
                    autoComplete="nope"
                    type="number"
                    min="0"
                    placeholder={t("signup.inquiriesPlacer")}
                    onChange={(e) => setInquiries(e.target.value)}
                  />
                </div>
              </Form.Group>
              <Form.Group
                className="mb-3 bodyRegular d-flex"
                controlId="formBasicCheckbox"
              >
                <Form.Check
                  className="div-checkbox-terms-singup blackCol aceptTerms d-flex align-items-center"
                  type="checkbox"
                  onClick={() => setCheckTerms(!checkTerms)}
                  label={t("signup.accept")}
                />
                <a
                  href="//inkup.io/tyc/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="termsLink"
                >
                  <p className="mb-0">&nbsp;{t("signup.terms")}</p>
                </a>
              </Form.Group>
            </div>
            <div className="w-100 d-flex align-items-center justify-content-center">
              <Button
                type="button"
                onClick={onFormSubmit}
                disabled={checkTerms === false}
                className="rounded-pill bottonSingup bodyDetailsBold"
              >
                {t("signup.register")}
              </Button>
            </div>
          </Form>
        </div>
      </Col>
    </Row>
  );
};
